<template>

    <div class="proslist" v-if="list && list.length">

      <h2 class="divider-sec">
        {{ title }}
      </h2>

      <GiveawayBoxCompact
        v-for="(opt,ind) in list"
        :key="ind"
        :name="opt.short_name"
        :link="opt.link"
        :cover="opt.cover_back_small"
        />

    </div>

</template>

<script>
 


export default {   
  components: {   
    
  },
  props: [
    'title',
    'list'
  ],
  data () {
    return {            
    }
  },
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
 
 

.proslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.proslist.centered {
  justify-content: center;
}


</style>
